import { StyleSheet } from 'aphrodite';
import { background, brandPrimaryText } from '../../../styles/colors';
import { BrowserMediaQueries, CssSelectors } from '../../../styles/styles';

const entityConversationComposerContactHeaderHeight = 60;
const entityConversationComposerHeaderHeight = 16;
export const styleSheet = StyleSheet.create({
	container: {},
	entityConversationComposer: {
		height: '100%',
	},
	entityConversationComposerConversation: {
		background: '#f9f9f9',
		display: 'flex',
		flex: '1 0 30%',
		flexDirection: 'column',
		height: `calc(100% - ${entityConversationComposerContactHeaderHeight + entityConversationComposerHeaderHeight}px)`,
		...CssSelectors.allChildren(
			{
				display: 'flex',
				flexDirection: 'column-reverse',
				flexGrow: 1,
				padding: '10px 20px',
				[BrowserMediaQueries.IE11]: {
					'-ms-overflow-style': 'none',
					flexDirection: 'column',
					transform: 'rotate(180deg)',
				},
			},
			'.conversation'
		),
		...CssSelectors.allChildren(
			{
				padding: '8px 20px 20px',
			},
			'.text-message-input'
		),
	},
	entityConversationComposerConversationContactHeader: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: entityConversationComposerContactHeaderHeight,
		justifyContent: 'center',
	},
	entityConversationComposerConversationHeader: {
		alignItems: 'center',
		display: 'flex',
		height: entityConversationComposerHeaderHeight,
		justifyContent: 'flex-end',
	},
	modalClassName: {
		...CssSelectors.allDescendants(
			{
				height: 'calc(100vh - 100px)',
				width: 620,
			},
			'.modal-overlay-content'
		),
		...CssSelectors.allDescendants(
			{
				borderLeft: 'none',
				borderRight: 'none',
			},
			'.conversation-container'
		),
	},
	textBubbleContainer: {
		background,
		padding: 20,
	},
	textShowMore: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 10,
		...CssSelectors.allChildren({
			color: brandPrimaryText,
			cursor: 'pointer',
			fontSize: 14,
		}),
	},
	loadingSpinnerContainer: {
		position: 'relative',
		top: '50%',
	},
});
