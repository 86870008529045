import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, titles } from '../../../styles/colors';
import { BreakPoints, CssSelectors, Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	actionBar: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 14,
		justifyContent: 'space-between',
	},
	actionBarLabel: {
		marginBottom: 2,
	},
	actionBarName: {
		color: brandPrimaryHover,
	},
	container: {
		width: 730,
		[BreakPoints.forMinWidth(1200)]: {
			maxMidth: 820,
			minWidth: 730,
		},
	},
	dayPickerPopoverContent: {
		background: '#fff',
		boxSizing: 'border-box',
		padding: '10px 0',
	},
	dayPickerPopoverFooter: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	dayPickerPopoverHeader: {
		boxSizing: 'border-box',
		padding: '0 20px',
		textAlign: 'right',
	},
	dayPickerPopoverTime: {
		margin: '10px 0',
		textAlign: 'center',
	},
	dayPickerPopoverTimeDropDown: {
		margin: 'auto',
		...CssSelectors.allChildren(
			{
				minWidth: 120,
			},
			'.select-box-menu'
		),
	},
	dayPickerPopoverTimeDropdownOption: {
		fontSize: 14,
	},
	dayPickerPopoverTimeDropdownTrigger: {
		fontSize: 14,
		height: 'auto',
		width: 108,
	},
	header: {
		color: titles,
		fontSize: 18,
		marginBottom: 22,
	},
	modalContent: {
		display: 'flex',
		flexDirection: 'column',
		transform: 'none',
		transition: 'all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		opacity: 1,
		overflowY: 'auto',
		overflowX: 'hidden',
		maxHeight: '90vh',
	},
	sentStatus: {
		alignItems: 'center',
		display: 'flex',
		position: 'relative',
	},
	sentStatusCheckmark: {
		left: -18,
		position: 'absolute',
		top: '50%',
		transform: 'translate3d(0, -50%, 0)',
	},
	stepsTable: {
		marginTop: 22,
	},
	stepsTableActionsCol: {
		flexShrink: 0,
		width: 60,
	},
	stepsTableBodyRow: {
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 14,
		padding: '5px 0',
	},
	stepsTableBodyRowEditableDate: {},
	stepsTableBodyRowName: {
		maxWidth: 'calc(100% - 40px)',
	},
	stepsTableBodyRowNameIcon: {
		flexShrink: 0,
	},
	stepsTableDateCol: {
		flexShrink: 0,
		width: 100,
	},
	stepsTableNameCol: {
		flexGrow: 1,
	},
	stepsTableRow: {
		...Layouts.horizontalStack(30),
	},
	stepsTableStatusCol: {
		flexShrink: 0,
		width: 80,
	},
	stepsTableSenderCol: {
		flexShrink: 0,
		width: 120,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});
