import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { baseStyleSheet } from '../../../styles/styles';
import { Modal } from '../../Modal';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';

interface IProps {
	showRegistrationRequiredModal: boolean;
	telephonyTextSettings: Api.TextSettings;
	onRequestClose: () => void;
}

export const TextRegistrationRequiredModal = ({
	showRegistrationRequiredModal,
	telephonyTextSettings,
	onRequestClose,
}: IProps) => {
	const renderContactVerbiage = () => {
		return telephonyTextSettings?.customerSuccessManagerEmailAddress ? (
			<span>
				Please contact your success manager at{' '}
				<b>
					<a
						className={css(styleSheet.modalBrandLink)}
						href={`mailto:${telephonyTextSettings.customerSuccessManagerEmailAddress}}`}
					>
						{telephonyTextSettings.customerSuccessManagerEmailAddress}
					</a>
				</b>{' '}
				for assistance with registering.
			</span>
		) : (
			<span>
				Please contact Levitate Support at{' '}
				<b>
					<a className={css(styleSheet.modalBrandLink)} href='mailto:support@levitateapp.com'>
						support@levitateapp.com
					</a>
				</b>{' '}
				for assistance with registering.
			</span>
		);
	};

	return (
		<Modal isOpen={showRegistrationRequiredModal} useDefaultHeader={true} onRequestClose={onRequestClose}>
			<div className={css(styleSheet.modal)}>
				<figure className={css(styleSheet.modalIcon)}>
					<WarningIcon />
				</figure>
				<h5 className={css(styleSheet.modalTitle)}>Effective December 1, 2024</h5>
				<section className={css(styleSheet.modalDescription)}>
					<p>
						Due to new regulations being enforced by cell carriers, your Levitate text message traffic will be blocked
						on December 1st by Syniverse <b>if you do not register.</b>
					</p>
					<p>
						<b>
							If you want to continue to use texting through Levitate, we are more than happy to assist you through this
							process.
						</b>{' '}
						{renderContactVerbiage()} For quick answers related to text registration, refer to the Levitate{' '}
						<a
							className={css(styleSheet.modalBrandLink)}
							href='https://help.levitate.ai/category/349-text-registration'
							target='_blank'
							rel='noreferrer'
						>
							help
						</a>{' '}
						articles.
					</p>
					<p>If you do not wish to continue using this feature, please let us know and we will disable it.</p>
				</section>
				<footer className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={onRequestClose}>
						<span>Close</span>
					</button>
				</footer>
			</div>
		</Modal>
	);
};
