import * as React from 'react';
import { isExemptFromRegistration } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContextGuard } from '../../../../models/hooks/useContextGuard';
import { usePhoneNumberUserQuery, useTelephonyTextSettingsQuery } from '../../../../queries';

export function useTextingCampaignRegistration() {
	const [showRegistrationRequiredModal, setShowRegistrationRequiredModal] = React.useState(false);
	const [isCampaignRegistrationRequired, setIsCampaignRegistrationRequired] = React.useState(false);
	const [showRegistrationInProgressModal, setShowRegistrationInProgressModal] = React.useState(false);
	const [isRegistrationInProgress, setIsRegistrationInProgress] = React.useState(false);

	const userSession = useUserSession();
	const userAccountId = userSession?.account?.id;

	const textSettingsQueryOptions = isCampaignRegistrationRequired || isRegistrationInProgress ? { cacheTime: 0 } : {};
	const telephonyTextSettingsQuery = useTelephonyTextSettingsQuery(textSettingsQueryOptions);
	const requiresCampaignRegistration = telephonyTextSettingsQuery.data?.requiresCampaignRegistration;
	React.useEffect(() => {
		setRegistrationRequiredModalValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAccountId, requiresCampaignRegistration]);

	const setRegistrationRequiredModalValues = () => {
		if (!userAccountId) {
			return;
		}

		if (isExemptFromRegistration(userAccountId)) {
			setShowRegistrationRequiredModal(false);
			setIsCampaignRegistrationRequired(false);
		} else {
			setShowRegistrationRequiredModal(requiresCampaignRegistration);
			setIsCampaignRegistrationRequired(requiresCampaignRegistration);
		}
	};

	const phoneNumberUserQuery = usePhoneNumberUserQuery();
	React.useEffect(() => {
		setInProgressModalValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAccountId, telephonyTextSettingsQuery.isLoading, requiresCampaignRegistration, phoneNumberUserQuery.data]);

	const setInProgressModalValues = () => {
		if (!userAccountId) {
			return;
		}

		if (isExemptFromRegistration(userAccountId)) {
			setShowRegistrationInProgressModal(false);
			setIsRegistrationInProgress(false);
		} else {
			if (!telephonyTextSettingsQuery.isLoading && !requiresCampaignRegistration) {
				const phoneNumbers = phoneNumberUserQuery.data;
				if (!phoneNumbers || phoneNumbers.length === 0) {
					setShowRegistrationInProgressModal(false);
				} else {
					const isInProgress = phoneNumbers[0].phoneNumber.campaignId ? false : true;
					setShowRegistrationInProgressModal(isInProgress);
					setIsRegistrationInProgress(isInProgress);
				}
			}
		}
	};

	const checkShowRegistration = React.useCallback(() => {
		setRegistrationRequiredModalValues();
		setInProgressModalValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAccountId, telephonyTextSettingsQuery.isLoading, requiresCampaignRegistration, phoneNumberUserQuery.data]);

	const context = React.useMemo(
		() => ({
			showRegistrationRequiredModal,
			isCampaignRegistrationRequired,
			showRegistrationInProgressModal,
			isRegistrationInProgress,
			setShowRegistrationRequiredModal,
			setIsCampaignRegistrationRequired,
			setShowRegistrationInProgressModal,
			setIsRegistrationInProgress,
			isLoading: telephonyTextSettingsQuery.isLoading || phoneNumberUserQuery.isLoading,
			textSettings: telephonyTextSettingsQuery.data,
			checkShowRegistration,
		}),
		[
			showRegistrationRequiredModal,
			isCampaignRegistrationRequired,
			showRegistrationInProgressModal,
			isRegistrationInProgress,
			telephonyTextSettingsQuery.isLoading,
			phoneNumberUserQuery.isLoading,
			telephonyTextSettingsQuery.data,
			checkShowRegistration,
		]
	);

	return context;
}

export type ITextingCampaignRegistrationContext = ReturnType<typeof useTextingCampaignRegistration>;

export const TextingCampaignRegistrationContext = React.createContext<ITextingCampaignRegistrationContext>(null);

export const useTextingCampaignRegistrationContext = () =>
	useContextGuard(TextingCampaignRegistrationContext, 'TextingCampaignRegistrationContext');

export function TextingCampaignRegistrationContextProvider({ children }: React.PropsWithChildren<any>) {
	const context = useTextingCampaignRegistration();
	return (
		<TextingCampaignRegistrationContext.Provider value={context}>
			{children}
		</TextingCampaignRegistrationContext.Provider>
	);
}
