import { StyleSheet } from 'aphrodite';
import { brandPrimary, darkGrayFontColor } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	footer: {
		display: 'flex',
		paddingTop: 15,
		gap: 10,
		justifyContent: 'center',
	},
	modal: {
		width: 400,
		textAlign: 'center',
	},
	modalTitle: {
		color: brandPrimary,
		height: 20,
	},
	modalDescription: {
		color: darkGrayFontColor,
		fontSize: 12,
		lineHeight: 1.5,
	},
	modalIcon: {
		marginBottom: 10,
	},
});
