import { css } from 'aphrodite';
import { baseStyleSheet } from '../../../styles/styles';
import { Modal } from '../../Modal';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { styleSheet } from './styles';

interface IProps {
	showRegistrationRequiredModal: boolean;
	onRequestClose: () => void;
}

export const TextRegistrationInProgressModal = ({ showRegistrationRequiredModal, onRequestClose }: IProps) => {
	return (
		<Modal isOpen={showRegistrationRequiredModal} useDefaultHeader={true} onRequestClose={onRequestClose}>
			<div className={css(styleSheet.modal)}>
				<figure className={css(styleSheet.modalIcon)}>
					<WarningIcon />
				</figure>
				<h5 className={css(styleSheet.modalTitle)}>Registration In Progress</h5>
				<section className={css(styleSheet.modalDescription)}>
					<p>Your Levitate texting number is in the process of being finalized. Please check back again soon.</p>
				</section>
				<footer className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={onRequestClose}>
						<span>Close</span>
					</button>
				</footer>
			</div>
		</Modal>
	);
};
