import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { getFormattedPhoneNumber } from '../../../../models/UiUtils';
import { useErrorMessages, useTextMessaging } from '../../../../models/hooks/appStateHooks';
import { useTextingCampaignRegistrationContext } from '../../../containers/Texting/TextingCampaignRegistrationPrompt/context';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import { asModalComponent } from '../../Modal';
import { Conversation } from '../../texting/Conversation';
import { MessageBubble } from '../../texting/MessageBubble';
import { TextMessageInput } from '../../texting/TextMessageInput';
import { TextRegistrationInProgressModal } from '../../texting/TextRegistrationInProgressModal';
import { TextRegistrationRequiredModal } from '../../texting/TextRegistrationRequiredModal';
import { styleSheet } from './styles';

export interface IEntityConversationComposerProps extends IModalContext<boolean> {
	className?: string;
	conversation: Api.ConversationViewModel;
	styles?: StyleDeclarationValue[];
}

interface IConversationComposerTextMessage extends Partial<Api.ITextMessage> {
	file?: File;
	pendingId?: string;
}

const EntityConversationComposerBase: React.FC<IEntityConversationComposerProps> = observer(props => {
	const { styles = [], className = '', conversation, parentModal } = props;
	const logger = useEventLogging('EntityConversationComposer');
	const errorMessages = useErrorMessages();
	// @ts-ignore
	const [pendingTextMessages, setPendingTextMessages] = React.useState<IConversationComposerTextMessage[]>(null);

	const {
		showRegistrationRequiredModal,
		isCampaignRegistrationRequired,
		showRegistrationInProgressModal,
		isRegistrationInProgress,
		setShowRegistrationRequiredModal,
		setShowRegistrationInProgressModal,
		isLoading,
		textSettings,
		checkShowRegistration,
	} = useTextingCampaignRegistrationContext();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(checkShowRegistration, []);

	const didSendMessageRef = React.useRef(false);
	const textMessagingVm = useTextMessaging();

	const renderPlaceholderMessages = () => {
		const pendingHasAttachment = !!pendingTextMessages?.find(x => x.file);
		return pendingTextMessages?.map((textMessage, i, all) => {
			return (
				<MessageBubble
					className='message'
					// @ts-ignore
					id={textMessage.pendingId}
					key={`${textMessage.pendingId}-${i}`}
					showStatus={!pendingHasAttachment || i === all.length - 1}
					textMessage={textMessage}
				/>
			);
		});
	};

	const sendTextMessage = React.useCallback(
		async (msg: string, attachments: Api.AttachmentsToBeUploadedViewModel<File>) => {
			const pendingMessages: IConversationComposerTextMessage[] = [];
			if (msg) {
				const textMessage: IConversationComposerTextMessage = {
					direction: Api.Direction.Outbound,
					pendingId: uuidgen(),
					status: Api.TextStatus.Sending,
					text: msg,
				};
				pendingMessages.push(textMessage);
			}

			if (attachments) {
				attachments.attachments.forEach(file => {
					const textMessage: IConversationComposerTextMessage = {
						direction: Api.Direction.Outbound,
						file,
						pendingId: uuidgen(),
						status: Api.TextStatus.Sending,
					};
					pendingMessages.push(textMessage);
				});
			}

			try {
				setPendingTextMessages(pendingMessages);
				await conversation?.sendMessage(msg, attachments);
				// @ts-ignore
				setPendingTextMessages(null);
				didSendMessageRef.current = true;
			} catch (err) {
				// @ts-ignore
				logger.logApiError('SendTextMessage-Error', err);
				// @ts-ignore
				errorMessages.pushApiError(Api.asApiError(err));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[conversation]
	);

	const onClose = () => parentModal?.onRequestClose(didSendMessageRef.current);

	const onCloseRegistrationWarningClicked = () => {
		setShowRegistrationRequiredModal(false);
	};

	const onCloseRegistrationInProgressClicked = () => {
		setShowRegistrationInProgressModal(false);
	};

	const recipient = conversation?.toNumbers?.[0];
	return isLoading ? (
		<div
			className={`${css(styleSheet.entityConversationComposer, styleSheet.loadingSpinnerContainer)} ${className} ${css(...styles)}`}
		>
			<LoadingSpinner type='small' />
		</div>
	) : (
		<div className={`${css(styleSheet.entityConversationComposer)} ${className} ${css(...styles)}`}>
			<div className={css(styleSheet.entityConversationComposerConversationHeader)}>
				<DeprecatedCloseButton onClick={onClose} />
			</div>
			<div
				className={css(baseStyleSheet.verticalStack, styleSheet.entityConversationComposerConversationContactHeader)}
			>
				<div>{Api.VmUtils.getDisplayName(recipient?.contact)}</div>
				{/* @ts-ignore */}
				<div>{`(${getFormattedPhoneNumber(recipient?.number?.e164)})`}</div>
			</div>
			<div className={css(styleSheet.entityConversationComposerConversation)}>
				<Conversation
					className='conversation'
					conversation={conversation}
					phoneNumber={textMessagingVm.phoneNumberOrder}
					placeholderMessages={renderPlaceholderMessages()}
					processing={!conversation?.isLoaded && !conversation?.textMessages?.length}
				/>
				<TextMessageInput
					className='text-message-input'
					conversation={conversation}
					onSend={sendTextMessage}
					hideTextSend={isCampaignRegistrationRequired || isRegistrationInProgress}
				/>
			</div>
			<TextRegistrationRequiredModal
				showRegistrationRequiredModal={showRegistrationRequiredModal}
				telephonyTextSettings={textSettings}
				onRequestClose={onCloseRegistrationWarningClicked}
			/>
			<TextRegistrationInProgressModal
				showRegistrationRequiredModal={showRegistrationInProgressModal}
				onRequestClose={onCloseRegistrationInProgressClicked}
			/>
		</div>
	);
});

export const EntityConversationComposerModal = asModalComponent(
	inject(ModalChildComponentContextKey)(EntityConversationComposerBase),
	{
		shouldCloseOnOverlayClick: false,
		useDefaultHeader: false,
	}
);
